
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
const SchoolInformation = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="School Information CBSE" breadcrumbName="School Information CBSE" />
      <div className="container body_height">
        <div className="row">
          <div className="col-md-12">
            <div className="tablescroll">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Name of the school  with address: (Strictly as per Affiliation sanction Letter or as permitted by the Board) with pin code no</td>
                    <td>AIR FORCE GOLDEN JUBILEE INSTITUTE SUBROTO PARK, NEW DELHI-110 010</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(i) E-mail </td>
                    <td>principal.afgji@gmail.com</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(ii) Ph. No</td>
                    <td>011-25692819 </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(iii) Fax No.</td>
                    <td>011-25691957 </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>Year of establishment of school</td>
                    <td>1986</td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>Whether NOC from State/UT or recommendation of Embassy of India obtained?</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(i) NOC No.</td>
                    <td>NOC taken from Directorate of Education vide their Letter No DE/Zone-22/D.W/87/2392-Plg </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(ii) NOC issuing date</td>
                    <td>05.03.87 </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>Is the school is recognized, if yes by which Authority </td>
                    <td>Directorate of Education, Delhi Administration </td>
                  </tr>
                  <tr>
                    <td>5.</td>
                    <td>Status of affiliation : Permanent/Regular/Provisional</td>
                    <td>Provisional (Permanent affiliation under process) </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(i) Affiliation No </td>
                    <td>2780009</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(ii) Affiliation with the Board since</td>
                    <td>1985</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(iii) Extension of affiliation upto</td>
                    <td>2018</td>
                  </tr>
                  <tr>
                    <td>6.</td>
                    <td>Name of Trust/Society/Company Registered under Section 25 of the Company Act, 1956. Period upto which Registration Of the Company Act, 1956 Period upto which Registration Of Trust/Society is valid </td>
                    <td>IAF Registration No SSS/Air HQ/21/04 year 2004
                      Sl No 126</td>
                  </tr>
                  <tr>
                    <td>7.</td>
                    <td>List of members of school Managing Committee with their Address/tenure and post Held</td>
                    <td>Click here for details of Managing Committee</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SchoolInformation