import { Link } from 'react-router-dom'
const Breadcrumb = ({ breadcrumbName, pageTitle }) => {
    return(
        <>
            <div class="innerbg">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <h2 class="innerhead">{pageTitle}</h2>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <ol class="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li class="active"><Link>{breadcrumbName}</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Breadcrumb