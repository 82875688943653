
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const MandatoryInformation = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Mandatory Information" breadcrumbName="Mandatory Information" />
           
<div className="container body_height">
  <div className="row">
    <div className="col-md-12">
      <div className="tablescroll">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>1</td>
              <td>NAME OF THE SCHOOL</td>
              <td>AIR FORCE GOLDEN JUBILEE INSTITUTE</td>
              
            </tr>
            <tr>
              <td>2</td>
              <td>AFFILIATION NO.</td>
              <td>2780009</td>
            </tr>
            <tr>
              <td>3</td>
              <td>SCHOOL CODE</td>
              <td>27131 </td>
            </tr>
            <tr>
              <td>4</td>
              <td>ADDRESS OF SCHOOL</td>
              <td>SUBROTO PARK DELHI CANTT, NEW DELHI </td>
            </tr>
            <tr>
              <td>5</td>
              <td>PRINCIPAL NAME & QUALIFICATION</td>
              <td>ALKA SINGH <br />M.A. M.ED.</td>
            </tr>
            <tr>
              <td>6</td>
              <td>SCHOOL EMAIL ID</td>
              <td>AFGJISP@GMAIL.COM </td>
            </tr>
            <tr>
              <td>7</td>
              <td>CONTACT DETAILS</td>
              <td>01125692819</td>
            </tr>
            <tr>
              <td>8</td>
              <td>COPIES OF AFFILIATION</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/Affiliation.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>9</td>
              <td>SOCITIES CERTIFICATE </td>
                <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/Society-Certicate.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>10</td>
              <td>NOC IF ANY</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/NOC-Certitficate.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>11</td>
              <td>RECOGINITION  CERTIFICATE UNDER RTE ACT 2009  </td>
               <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/Recognition.pdf" target="_blank">Click Here</Link></td>
              
            </tr>
            <tr>
              <td>12</td>
              <td>BUILDING SAFETY CERTIFICATE</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/Building-Safety.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>13</td>
              <td>FIRE SAFETY CERTIFICATE</td>
               <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/fire.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>14</td>
              <td>SELF CERTIFICATION FOR AFFILIATION / UPGRADATION / EXTENSION OF AFFILIATION</td>
               <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/self-certification.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>15</td>
              <td>FEE STRUCTURE</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/fee_structure.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>16</td>
              <td>ANNUAL ACADEMIC CALENDAR</td>
               <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/School-Calender.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>17</td>
              <td>LIST OF SCHOOL MANAGEMENT COMMITTEE</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/SMC.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>18</td>
              <td>LIST OF PTA MEMBERS</td>
              <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/pta.pdf" target="_blank">Click Here</Link></td>
            </tr>
            <tr>
              <td>19</td>
              <td>LAST THREE YEARS BOARD RESULT</td>
            <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/Result_X.pdf" target="_blank">Click Here (X Result)</Link>  ,
            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/RESULT _XII.pdf" target="_blank">Click Here (XII Result)</Link></td>
            </tr>

             <tr>
              <td>20</td>
              <td>TEACHING FACULTY DETAILS</td>
             <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/faculty.pdf" target="_blank">Click Here</Link></td>
            </tr>           
             <tr>
              <td>21</td>
              <td>SCHOOL INFRASTRUCTURE</td>
               <td><Link to="https://www.afgji.in/infrastructure_special_wing" target="_blank"> Special Wing</Link> ,
            <Link to="https://www.afgji.in/infrastructure_primary_wing" target="_blank"> Primary Wing</Link> ,
            <Link to="https://www.afgji.in/infrastructure_senior_wing" target="_blank"> Senior Wing</Link></td>
            </tr>
            
             <tr>
              <td>22</td>
              <td>WATER ,HEALTH AND SANITATION CERTIFICATES </td>
             <td><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Mandatory/water-certificate.pdf" target="_blank">Click Here</Link></td>
            </tr>  



          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
                <Footer />
        </>
    )
}

export default MandatoryInformation