
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Newsletter = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Newsletter" breadcrumbName="Newsletter" />
          
<div className="container body_height">
  <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://heyzine.com/flip-book/7b83524add.html#page/1" target="_blank"> Volume XII  Session: 2022-23 </Link> </div>
</div>
<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://heyzine.com/flip-book/059b8a7b21.html#page/1" target="_blank"> Volume XI   Session: 2021-22 </Link> </div>
</div>
<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
      <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Newsletter.pdf" target="_blank"> Volume X  Session: 2020-21 </Link> </div>
</div>
  </div>
</div>

                <Footer />
        </>
    )
}

export default Newsletter