import React, { useEffect ,useState} from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";  
import { getEventsinner } from '../Service/Api';
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb"; 

const Events = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Events - Presentation Convent School Delhi';  
      }, []);
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner();
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const month = new Date(item.date).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth();
                    return monthNames[month] === selectedMonth;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Sort the filtered data by month in descending order
        filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
       
       <Header />
            <Breadcrumb pageTitle="Events/ Achievements" breadcrumbName="Events/ Achievements" />
        
            
            <div className="innerSection">
            <div className="container">
                
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length} </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                    {filterData &&
    filterData.map((item, index) => (
        <div className="col-sm-12 col-md-12 col-xl-6 col-lg-6 eventCount" key={index}>
            <div className="eventbox">
                <div className="eventboximg">
                    {Array.isArray(item.attachments) && item.attachments.length > 0 && (
                        <div className="eventattch">
                            <Link target="_blank" to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}>
                                <i className="bi bi-paperclip"></i>
                            </Link>
                        </div>
                    )}

                    {Array.isArray(item.images) && item.images.length > 0 ? (
                        <img 
                            src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images[0]}`} 
                            className="img-fluid" 
                            alt="AFGJI" 
                        />
                    ) : (
                        <img 
                            src={`https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/event.jpg`} 
                            className="img-fluid" 
                            alt="AFGJI" 
                        />
                    )}
                </div>
                <div className="eventboxdesc">
                    <div className="eventpgh">
                        <h4><b>{item.title}</b></h4>
                        <p>{item.description}</p>
                    </div>
                    <div className="eventdate">
                        <span><i className="bi bi-alarm"></i> {item.time}</span>
                        <span><i className="bi bi-calendar"></i> {item.date}</span>
                        <span><i className="bi bi-geo-alt"></i> {item.venue}</span>
                    </div>
                </div>
            </div>
        </div>
    ))
}

                    </div>
                    </div>  
                    </div> 
                   <Footer/> 
        </>
    );
}

export default Events;
