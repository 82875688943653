import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import News from '../Component/News'
import Circular from '../Component/Circular'
import Popup from '../Component/Popup'
import Events from '../Component/Events'
import { Carousel } from 'react-bootstrap'
import OnlineRegistration from '../Component/OnlineRegistration'
import TopperX from '../Component/TopperX'
import TopperXII from '../Component/TopperXII'
import Gallery from '../Component/Gallery'
import {Link} from 'react-router-dom'

const Home = () => {
  return (
    <>
      <div className="erp_links"> <Link to="http://www.afgjicampuscare.in/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Campulogo.png" alt="AFGJI" className="img-responsive" /></Link> </div>

      <div className="OnlineFee2">
        <Link to="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=4764384" target="_blank">&nbsp; Online fee payment: SBI e collect</Link>
      </div>
      <Popup />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-9 no-pad slidernotify">
            <div id="myCarousel" className="carousel slide" data-ride="carousel">

              <div className="carousel-inner">
                <Carousel>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-10.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-11.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-12.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-13.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-14.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-15.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-0.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-1.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-2.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-3.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-4.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-5.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-6.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-7.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-8.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                  <Carousel.Item><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/slide-9.jpg" alt="AFGJI" width="100%" className="slider" /></Carousel.Item>
                </Carousel>
              </div>
              
              </div> 
            <OnlineRegistration />
          
            <div className="OnlineR">
              <span className="bounce1">
                <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Corrigendum.pdf" target="_blank" className="parantlogo "> Corrigendum  Notification   </Link>

              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 no-pad news_area">
            <div className="col-md-12">
              <h4>News/Notice <Link to="news"><i className="bi bi-arrow-right"></i></Link></h4>
              <hr />
              <marquee scrollamount="3" scrolldelay="30" direction="up" behavior="scroll" align="left" onmouseover="stop();" onmouseout="start();" className="news_mscrool">
 
                <News />
 
              </marquee>
            </div>
            <div className="col-md-12 circularbox">
              <h4>Circular<Link to="circular"><i className="bi bi-arrow-right"></i></Link></h4>
              <div id="circularbox" className="carousel slide" data-ride="carousel">

                <div className="carousel-inner">
                  <Circular />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 graybg">
            <div className="pad_box_left">
              <div className="col-md-12">
                <h1 className="main_head">About <span>AFGJI</span></h1>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/about.jpg" className="img-responsive  center-block" alt="AFGJI" /></div>
              <div className="col-xs-8 col-md-8 col-md-8">
                <p className="about-tran">Air Force Golden Jubilee Institute, located at Subroto Park, New Delhi is one of the leading public schools run by Indian Air Force Educational and Cultural Society. The School was conceptualised as an institution for special children in the Air Force Golden Jubilee year, and started in the year 1985, as a school specifically for children with special needs but subsequently it was felt that an integrated Institution would be a better option, as it would provide the special children an opportunity of receiving education in the atmosphere of a normal school.</p>
                <div className="clr10"></div>
                <h5><Link to="/ourschool" className="more" role="button">More About AFGJI</Link></h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bluebg">
            <div className="pad_box_right">
              <div className="col-md-12">
                <h2 className="main_head white">Principal's Desk</h2>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/principal-big.jpg" className="img-responsive center-block" alt="AFGJI" />


              </div>
              <div className="col-xs-8 col-sm-8 col-md-8">
                <p className="about-tran">

                  Air Force Golden Jubilee Institute is managed by the IAF Educational and Cultural Society.  It is a pioneer in the field of Inclusive Education since 1986.  Its objective is to impart the highest quality of education to all its learners, in order to create an equitable, inclusive and plural society as envisaged by our Constitution.  The reforms in school education have to be aligned with the aspirations of a ‘Viksit Bharat’ and the school is committed to create a learning environment which cultivates intellect and fortifies character.  The emphasis is on inculcating 21st century skills – critical thinking, problem solving, creativity, leadership, social skills etc.  We, at AFGJI are committed to train our students to blossom into sincere and compassionate citizens, who will be conscious of the needs of the society and the environment.  They will also have the technological prowess to suits the needs of the ever evolving global scenario.</p>


                <div className="clr10"></div>
                <h5><Link to="/message_desk" className="more" role="button">Read Full Message</Link></h5>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 event-section">
            <h2 className="main_head text-center">Events & <span>Achievements</span></h2>
            <div className="heading-border-line"></div>
          </div>
          <div className="col-md-12">
            <Events />
          </div>
          <center>
            <Link to="/Events" className="btn btn-info btncenter" role="button"> More Events</Link>
          </center>
        </div>
      </div>
      <section id="testimonial" className="bg_cover pt-115 pb-115 topperbg" data-overlay="8">
        <div className="container">
          <div className="row testimonial-slide mt-70 slick-initialized slick-slider slick-dotted">
            <div className="col-sm-12 col-md-7">
              <div className="row">
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space">
                  <Link to="/activities-senior-wing"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/Activity_Senior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" />
                    <div className="overlay-box">
                      <div className="text">Activity Senior</div>
                    </div>
                  </Link>
                </div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space">
                  <Link to="/activities-primary-wing"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/Activity_Junior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" />
                    <div className="overlay-box">
                      <div className="text">Activity Junior </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xs-4 col-sm-3 col-md-3 galoverlay-box space"> <Link to="/infrastructure_senior_wing"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/Sports_Senior.jpg" className="img-responsive center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Sports Senior</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/infrastructure_primary_wing"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/Sports_Junior.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Sports Junior</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/infrastructure_special_wing"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/media.jpg" className="img-responsive center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Special Wing</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/special_honors"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/Special_Honors.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Special Honors</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/media_gallery"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/gallery/1.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Media</div>
                  </div>
                </Link></div>
                <div className="col-xs-4 col-sm-3 col-md-3  galoverlay-box space"> <Link to="/memories"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/box/memories.jpg" className="img-responsive  center-block roundimg" alt="AFGJI" />
                  <div className="overlay-box">
                    <div className="text">Memories</div>
                  </div>
                </Link></div>
              </div>
            </div>
            <div className="col-md-offset-1 col-md-4 col-sm-12">
              <div className="row">
                <div id="10topper" className="carousel slide" data-ride="carousel">

                  <div className="carousel-inner">
                    <TopperX />

                  </div>
                </div>
              </div>
              <div className="clr20"></div>
              <div className="row">
                <div id="12topper" className="carousel slide" data-ride="carousel">

                  <div className="carousel-inner">
                    <TopperXII />
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container tb-pad-20">
  <div className="row">
    <div className="col-md-12">
      <h2 className="main_head">Photo <span>Gallery</span> <Link to="/Gallery"><i className="bi bi-arrow-right"></i></Link></h2>
    </div>
  </div>
  
  <div className="row">
    <div className='col-md-12'>
      <Gallery />
      </div>
      </div>
     
    
        </div>
      <Footer />
    </>
  )
}

export default Home