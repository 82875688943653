import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from "../Component/Header";
import Footer from "../Component/Footer";  
import Breadcrumb from "../Component/Breadcrumb"; 
import LazyLoad from 'react-lazyload';
const SubGallery = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]); // Add id as a dependency


  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
  }));
  return (
    <>
      <Header /> 
            <Breadcrumb pageTitle="Category" breadcrumbName="Category" />
      
      <div className="body_height">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
              <div className="shrink"><Link to="/Gallery"><i className="bi bi-arrow-left"></i></Link></div>


            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
              <div><b>Event Date:</b> {" "}
                {new Date(data.date).toLocaleDateString("en-GB")}</div>


            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
              <div className="month-selection">
                <div><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>

              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-3 col-12">
              <div className="month-selection">
                <div><b>Total Photos:</b> {data?.attachments?.length}</div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">



              <div className="fulwidth">

                <div className="discription">
                  <h3 className="subhead">{data.title}</h3>
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="sub_gallery row">
                {data && data.attachments && data.attachments.length > 0 && (
                  data.attachments.map((img, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-12" key={index}>
                        <a data-magnify="gallery" data-src="" data-group="a" href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} onClick={() => setIndex(index)}>
                        <LazyLoad> 
                          <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} onClick={() => setIndex(index)} className="img-fluid" alt={`Image ${index}`} />
                        </LazyLoad>
                        </a>
                    </div>
                  ))
                )}

                {/* <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TAGOREBG/public/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default SubGallery