import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Tender = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [yearRanges, setYearRanges] = useState([]); 
  
    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        const circularData = await getCircular();
        setData(circularData);
        const circularYears = extractYears(circularData);
        setYearRanges(circularYears);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      if (data.length === 0) {
        return;
      }
    
      let filteredData = data.filter((item) => item.category === "Tender");
      let currentFilteredData = filterDataByYear(filteredData, selectedYear);
    
      // If no data for the selected year, fallback to previous year with data
      if (currentFilteredData.length === 0) {
        const availableYears = yearRanges;
        for (let i = 0; i < availableYears.length; i++) {
          const fallbackYear = availableYears[i];
          currentFilteredData = filterDataByYear(filteredData, fallbackYear);
          if (currentFilteredData.length > 0) {
            setSelectedYear(fallbackYear); // Update selected year to the fallback year
            break;
          }
        }
      }
    
      // Sort the currentFilteredData by date in descending order (latest on top)
      currentFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
    
      setFilteredData(currentFilteredData);
    }, [selectedYear, data, yearRanges]);
    
    
  
    const filterDataByYear = (data, year) => {
      if (year === "All") return data;
      return data.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const itemYear = new Date(item.date).getFullYear();
          return parseInt(year) === itemYear;
        }
        return false;
      });
    };
  
    const extractYears = (data) => {
      const years = data
        .filter((item) => item.category === "Circular")
        .map((item) => {
          if (item.date && item.date.trim() !== "") {
            return new Date(item.date).getFullYear().toString();
          }
          return null;
        })
        .filter((year, index, self) => year && self.indexOf(year) === index)
        .sort((a, b) => b - a); // Sort years in descending order
      return years;
    };
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Tender" breadcrumbName="Tender" />
            
        <div className="body_height container toppad-30">
            <div className="row">
                <div className="clr"></div>
                <div className="col-md-12">

                    <div className="col-xs-6 col-sm-6 col-md-6">
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6">
                    <select
                t id="ddlYear"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  
                >
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select> 
                    </div>
                    <div className="clr20"></div>



                    <div id="showThree" className="myDiv selected_year  ">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width="60%">Title</th>
                                    <th>Date</th>
                                    <th>Download</th>
                                </tr>
                            </thead>

                            <tbody>
                            {filterData.length > 0 ? (
                  filterData.map((item, index) => (
                    <tr key={index}>
    <td>{item.title}</td>
    <td>{item.date}</td>
    <td><Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} download target="_blank"> Download</Link></td>
</tr>
                    ))
                    ) : (
<tr>
    <td>-</td>
    <td>-</td>
    <td>-</td>
</tr>
                        )}

                             
                            </tbody>
                        </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
                <Footer />
        </>
    )
}

export default Tender