
import Footer from "../Component/Footer"
import Header from "../Component/Header"
import Breadcrumb from "../Component/Breadcrumb"; 
const Admission = () =>{
    return(
        <>
        <Header/>
      <Breadcrumb pageTitle="Admission Information" breadcrumbName="Admission Information" />
         
<div className="container body_height">
     <div className="row">
	<div className="col-md-12"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/cm.png" className="img-responsive center-block" alt="AFGJI"/></div>
	</div>
</div>
        <Footer/>
        </>
    )
}
export default Admission