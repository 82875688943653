import { useEffect, useState } from "react";
import { getFaculty } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Faculty = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("Admin"); // Default active tab

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFaculty();

      // Sort the data by class as numbers
      const sortedData = data.sort((a, b) => {
        return parseInt(a.order) - parseInt(b.order);
      });

      setData(sortedData);
      setFilteredData(sortedData); // Initially display all sorted data
    };
    fetchData();
  }, []);

  // Filter the data based on the active tab
  const filteredTabData = filteredData.filter(item => item.category === activeTab);

  return (
    <>
      <Header />
            <Breadcrumb pageTitle="Faculty List" breadcrumbName="Faculty List" />
       

      <div className="container body_height">
        <div className="row">
          <div className="col-md-3 card leftmenu">
            <div className="leftmenubg">
              <p className="menuhead">Important Links</p>
            </div>
            <div id="events">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className={activeTab === "Admin" ? "active" : ""}>
                  <Link to="#" onClick={() => setActiveTab("Admin")}>Admin</Link>
                </li>
                <li role="presentation" className={activeTab === "Senior" ? "active" : ""}>
                  <Link to="#" onClick={() => setActiveTab("Senior")}>Senior Wing</Link>
                </li>
                <li role="presentation" className={activeTab === "Primary" ? "active" : ""}>
                  <Link to="#" onClick={() => setActiveTab("Primary")}>Primary Wing</Link>
                </li>
                <li role="presentation" className={activeTab === "Special" ? "active" : ""}>
                  <Link to="#" onClick={() => setActiveTab("Special")}>Special Wing</Link>
                </li>
                <li role="presentation" className={activeTab === "Contractual" ? "active" : ""}>
                  <Link to="#" onClick={() => setActiveTab("Contractual")}>Contractual</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-9">
            <div className="tab-content">
              <div role="tabpanel" className={`tab-pane ${activeTab === "Admin" ? "active" : ""}`}>
                <h3 className="sub_head">Admin</h3>
                <div className="tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTabData.length > 0 ? (
                        filteredTabData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Repeat for other tabs */}
              <div role="tabpanel" className={`tab-pane ${activeTab === "Senior" ? "active" : ""}`}>
                <h3 className="sub_head">Senior Wing</h3>
                <div className="tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTabData.length > 0 ? (
                        filteredTabData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div role="tabpanel" className={`tab-pane ${activeTab === "Primary" ? "active" : ""}`}>
                <h3 className="sub_head">Primary Wing</h3>
                <div className="tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTabData.length > 0 ? (
                        filteredTabData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div role="tabpanel" className={`tab-pane ${activeTab === "Special" ? "active" : ""}`}>
                <h3 className="sub_head">Special Wing</h3>
                <div className="tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTabData.length > 0 ? (
                        filteredTabData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div role="tabpanel" className={`tab-pane ${activeTab === "Contractual" ? "active" : ""}`}>
                <h3 className="sub_head">Contractual</h3>
                <div className="tablescroll">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTabData.length > 0 ? (
                        filteredTabData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Faculty;





