import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
const Recruitment = () => {
    const [data, setData] = useState([]);  
 
  
    useEffect(() => {
      const fetchData = async () => {
        const circularData1 = await getCircular();
        const circularData= circularData1.filter((item) => item.category === "Recruitment")
        setData(circularData);  
      };
      fetchData();
    }, []);
  
 
    
    
  
    
   
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Recruitment" breadcrumbName="Recruitment" />
            
<div className="container body_height Circular-space">
   <div className="row">
      <div className="col-md-12">
         
         <div id="showThree" className="myDiv selected_year">
            <div className="row"> 

                {data.length > 0 ? (
                  data.map((item, index) => (


                    <div className="col-md-4 col-sm-6 col-xs-6 dips-grid"  key={index}>
                  <a href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank">
                     <div className="panel panel-default">
                        <div className="panel-body">
                           <div className="row">
                              <div className="col-md-3 col-sm-3"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/log.jpg" className="img-responsive center-block"/></div>
                              <div className="col-md-9 col-sm-9">
                                 <p className="panel-ppad">{item.title}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </a>
               </div>


                    
                  ))
                ) : (
                    <div className="col-md-4 col-sm-6 col-xs-6 dips-grid">
                    <a href="#" target="_blank">
                       <div className="panel panel-default">
                          <div className="panel-body">
                             <div className="row">
                                <div className="col-md-3 col-sm-3"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/log.jpg" className="img-responsive center-block"/></div>
                                <div className="col-md-9 col-sm-9">
                                   <p className="panel-ppad"> Recruitment Title</p>
                                </div>
                             </div>
                          </div>
                       </div>
                    </a>
                 </div>
                )}

 
    

            </div>
         </div>
       
     
      </div>
   </div>
</div>
                <Footer />
        </>
    )
}

export default Recruitment