
import Header from "../Component/Header";
import { Link } from 'react-router-dom'
import Footer from "../Component/Footer";
import Breadcrumb from "../Component/Breadcrumb";
const Classesviviii = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Classes VI to VIII" breadcrumbName="Classes VI to VIII" />

      <div className="container body_height">
        <div className="row">
          <div className="col-md-12">
            <h3 className="subhead">Time Table</h3>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/MID-TERM-EXAMINATION-CLASSES-VI-TO-XII.pdf" target="_blank">Mid Term Examination</Link></div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Time-Table/Senior/class-6-8.pdf" target="_blank">Class 6th to 8th</Link> </div>
          </div>
        </div>
        <div className="clr20"></div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="subhead">Syllabus</h3>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 6.pdf" target="_blank">Class 6th </Link> </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 7.pdf" target="_blank">Class 7th </Link> </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 8.pdf" target="_blank">Class 8th </Link> </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default Classesviviii