
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
const Uniformtiming = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Uniform & Timing" breadcrumbName="Uniform & Timing" />
           
<div className="container body_height">
  <div className="row">
    <div className="col-md-12">
      <h3 className="subhead">Summer Uniform </h3>
		<div className="table-responsive text-wrap"> 
        <table className="table table-striped">
 
          <thead>
            <tr>
              <th>Items</th>
              <th>Boys</th>
              <th>Girls</th>
            </tr>
          </thead> 
          <tbody>
            <tr>
              <td>Shirt </td>
              <td><strong>Common for all classes</strong> <br/> <br/>
AF blue Terrycot half sleeved shirt with pointed collars, pocket on left side with nylon buttons. Logo  of IAF E & C Society weaved on light blue fabric stitched on the centre of pocket .The name of school written in navy blue colour on a 2’’ sky blue colour cloth stitched on the top of the pocket.
</td>
              <td><strong>For class LKG-UKG</strong> <br/> <br/>
AF blue Terrycot half sleeved shirt with pointed collars without society logo . <br/> <br/>
<strong>For Class I – VIII</strong> <br/> <br/>
AF blue Terrycot half sleeved shirt with pointed collars, pocket on left side with nylon buttons. Logo  of IAF E & C Society weaved on light blue fabric stitched on the centre of pocket .The name of school written in navy blue colour on a 2’’ sky blue colour cloth stitched on the top of the pocket. <br/> <br/>
<strong>For class IX and above </strong> <br/> <br/>
AF blue half sleeved ‘V’ neck kameez with standing or choker collar and a navy blue waist coat stitched on sides with kameez. Logo of IAF E&C Society weaved on navy blue fabric stitched on left chest of waist coat. The name of school written in navy blue colour on a 2” sky blue colour cloth stitched above the logo on the left shoulder strap.
</td>
            </tr>
            <tr>
              <td>Tunic/Shorts/ Trousers/Skirt</td>
              <td>Navy blue cotton/terrycot shorts 4.5” above the mid knee , medium width with side pockets or Navy blue cotton/terrycot trousers with side pockets ,width of the lower end of trousers should be between 16 to 18 inches.</td>
              <td><strong>For LKG/UKG </strong> <br/> <br/>
Navy blue Tunic 2” below knee level with logo of  IAF E & C society weaved on navy blue fabric stitched on left chest . The name of school written in navy blue colour on a 2” sky blue colour cloth stitched above the logo on the left shoulder strap or the uniform approved for boys of LKG/ UKG may be worn by girl students as well . <br/> <br/>
<strong>For Class I-VIII</strong> <br/> <br/>
Navy blue terry/cotton divided skirt with knife pleats .2” wide waist band ,3” below knee level or Navy blue terry/cotton trouser with side pocket is optional . <br/> <br/>
<strong>For Class IX and above </strong><br/> <br/>
Navy blue straight cut trouser with 2’ waist band with side pockets.
 </td>
            </tr>
            <tr>
              <td>Shoes </td>
              <td colspan="2">Black Velcro attachment shoes with PU Sole.</td>
            </tr>
            <tr>
              <td>Socks </td>
              <td colspan="2">Sky blue colour Nylon/cotton socks with two navy blue stripes on top rim</td>
            </tr>
            <tr>
              <td>Belt  </td>
              <td colspan="2">Elasticated navy blue belt with board AF blue band in middle with chrome buckle printed with IAF E&C Society logo</td>
            </tr>
            <tr>
              <td>Head Gear  </td>
              <td>Navy blue headgear for Sikh boys</td>
              <td>Not applicable  </td>
            </tr>
            <tr>
              <td>Tie </td>
              <td colspan="2">School pattern tie with Navy blue background and slanting AF blue colour stripes. Ties are optional with summer uniform.</td>
            </tr>
            <tr>
              <td>Ribbon</td>
              <td>Not  applicable </td>
              <td>1 inch wide AF blue nylon ribbon or AF blue elastic bands or Hair Bands .</td>
            </tr>
          </tbody> 


        </table> 
      </div>
	  
      <h3 className="subhead">Winter Uniform </h3>
		<div className="table-responsive text-wrap">
      
        <table className="table table-striped">
 
          <thead>
            <tr>
              <th>Items</th>
              <th>Boys</th>
              <th>Girls</th>
            </tr>
          </thead> 
          <tbody>
            <tr>
              <td>Shirt </td>
              <td><strong>Common for all classes</strong> <br/> <br/>
AF blue terrycot full sleeved shirt with pointed collars, pocket on the left side and nylon buttons.</td>
<td><strong>For class LKG-UKG</strong> <br/> <br/>
AF blue Terrycot full sleeved shirt with pointed collars . <br/> <br/>
<strong>For Class I – VIII</strong> <br/> <br/>
AF blue Terrycot full sleeved shirt with pointed collars, pocket on left side with nylon buttons. <br/> <br/>
<strong>For class IX and above </strong> <br/> <br/>
AF blue full sleeved ‘V’ neck kameez with standing or choker collar.</td>
            </tr>
            <tr>
              <td>Tunic/Shorts/ Trousers/Skirt</td>
              <td>Steel grey woolen/worsted trousers with side pockets. Width of lower end of trousers should be between 16 to 18 inches.</td>
              <td><strong>For LKG/UKG </strong> <br/> <br/>
Steel grey woolen /worsted Tunic 2” below knee level .Steel grey leggings may be worn with Tunic if required or the uniform approved for boys of LG/UKG may be worn by girl students as well.<br/> <br/>
<strong>For Class I-VIII</strong> <br/> <br/>
Steel grey terrycot divided skirt with knife pleats .2” wide waist band 3’ below knee level. Steel grey leggings may be worm with skirt if required or steel grey woolen/worsted pants with side pockets is optional. <br/> <br/>
<strong>For Class IX and above </strong><br/> <br/>
Steel Gray straight cut trouser with 2” waist band with side pockets. 
 </td>
            </tr>
            <tr>
              <td>Shoes </td>
              <td colspan="2">Black Velcro attachment shoes with PU Sole.</td>
            </tr>
            <tr>
              <td>Socks </td>
              <td colspan="2">Sky blue colour Nylon/cotton socks with two navy blue stripes on top rim</td>
            </tr>
            <tr>
              <td>Pullover(Sleeveless & full Sleeve) </td>
              <td colspan="2">Navy blue’V’ neck pullover with weaved logo of IAF E & C Society stitched on the left side</td>
            </tr>
            <tr>
              <td>Blazer  </td>
              <td colspan="2">Navy blue single breasted blazer with Navy blue colour logo of IAF E & C Society weaved on the left side and 3 flat blue buttons.</td>
            </tr>
            <tr>
              <td>Jacket   </td>
              <td colspan="2">Unisex front Zip polar fleece hooded jacket with two side pockets. The Zipper should be dyed to match and elastic at cuffs .</td>
            </tr>
            <tr>
              <td>Belt  </td>
              <td colspan="2">Elasticated Navy Blue belt with board white band in middle a chrome buckle printed with IAF E & C Society monogram.</td>
            </tr>
            <tr>
              <td>Head Gear  </td>
              <td>Navy blue headgear for Sikh boys</td>
              <td>Not applicable  </td>
            </tr>
            <tr>
              <td>Tie </td>
              <td colspan="2">School pattern tie with Navy blue background and slanting AF blue colour stripes. Ties are optional with summer uniform.</td>
            </tr>
            <tr>
              <td>Ribbon</td>
              <td>Not  applicable </td>
              <td>1 inch wide AF blue nylon ribbon or AF blue elastic bands or Hair Bands .</td>
            </tr>
            <tr>
              <td>Woolen Cap ( optional) </td>
              <td colspan="2">A navy blue woolen cap</td>
            </tr>
          </tbody> 


        </table> 
      </div>
	  
      <h3 className="subhead">Uniform  &amp; Timings</h3>
      <p>The School uniform is to be bought from the school uniform store. The uniform purchased from the school store conforms to the standards set by the school. The House T-Shirts are also available at the store. All articles of the uniform are to be bought well in advance. Students will not be allowed to leave their classes to buy their uniform. Students may, however, visit the store during the break.</p>
      <p>Each House has been allotted a specific colour for the T-Shirt that will be worn by students on every Wednesday and any other occasions like sports activities &amp; inter house activities. The student is suppose to buy a T-Shirt from the school uniform shop. The same will be worn by the student with a pair of white shorts/ trousers (Boys) or a white skirt/trousers (Girls) and white socks with house colour stripes.</p>
      <p><b>Colour Scheme for Senior Wing</b></p>
      <ul className="checkList">
        <li>Subroto-Green</li>
        <li>Arjan-Red</li>
        <li>Pratap-Deep Blue</li>
        <li>Latif-Rust</li>
        <li>Lakshman-Yellow</li>
      </ul>
      <h5><b>Girls</b></h5>
      <p>1. Shirts to be neatly tucked in with belt visible.</p>
      <p>2. Hair bands must be the colour of the school shirt/skirt.</p>
      <p>3. Girls with shoulder length hair cut must wear hair bands. Hair length longer than shoulder must be braided.</p>
      <h5><b>Boys</b></h5>
      <p> 1. Only half-sleeve shirts to be worn in summers.</p>
      <p>2. Trousers to be worn instead of shorts from VIII grade onwards. Trousers are available for other classes also. </p>
      <p>3. Visible vests under shirts not permitted.</p>
      <p>4. Double breasted blazers or other variations in design and cloth not allowed.</p>
      <p>5. Trousers to be worn at waist level. Low waisted trousers not permitted.</p>
      <p>6. Shirts to be neatly tucked in with belt visible.</p>
      <p>7. Square toed and other fancy designs in shoes not allowed.</p>
      <p>8. Hair to be worn short. Spikes and gelled hairstyles not allowed. </p>
      <br/>
      <p>The day begins with assembly at 8.00 A.M. followed by teaching up to 1:45 P.M. Students are assisted and encouraged to give talks in the assembly on a wide variety of interesting and important topics. They are trained to sing patriotic songs and prayers. (The institute works from Monday to Friday. One Saturday (generally 1st Sat.) in a month is observed as a normal working day for the staff and 3rd Saturday with students.) <br/>
        <br/>
        The academic year of the school is from 1st of April to 31st March of the following year and is divided into two terms of approximately equal number of working days.</p>
    </div>
  </div>
</div>
                <Footer />
        </>
    )
}

export default Uniformtiming