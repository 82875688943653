import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb"; 

const Transport = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState('csw');

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Transport" breadcrumbName="Transport" />

      <div className="container body_height">
        <div className="row"> 
            <div className="col-md-3 leftmenu">
              <div className="leftmenubg">
                <p className="menuhead">Important Links</p>
              </div>
              <div id="events">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="nav-item">
                    <Link
                      className={`nav-link ${activeTab === 'csw' ? 'active' : ''}`}
                      to="#csw"
                      role="tab"
                      data-bs-toggle="tab"
                      onClick={() => setActiveTab('csw')}
                    >
                      BUS ROUTE LIST
                    </Link>
                  </li>
                  <li role="presentation" className="nav-item">
                    <Link
                      className={`nav-link ${activeTab === 'cpw' ? 'active' : ''}`}
                      to="#cpw"
                      role="tab"
                      data-bs-toggle="tab"
                      onClick={() => setActiveTab('cpw')}
                    >
                      TRANSPORT ADVISORY
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className={`tab-pane fade ${activeTab === 'csw' ? 'show active' : ''}`}
                  id="csw"
                >
                  <div className="col-md-12">
                    <h3 className="sub_head">BUS ROUTE LIST</h3>
                    <object
                      data="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/BusRouteList.pdf"
                      type="application/pdf"
                      width="100%"
                      height="810"
                      style={{ border: '2px solid #c1c1c1' }}
                    />
                  </div>
                </div>

                <div
                  role="tabpanel"
                  className={`tab-pane fade ${activeTab === 'cpw' ? 'show active' : ''}`}
                  id="cpw"
                >
                  <h3 className="sub_head">TRANSPORT ADVISORY</h3>
                  <div className="col-md-12 text-center">
                    <p className="black-bold">
                      <strong>Students Cir No. : 08/ 2016 -17</strong>
                      <strong>Jul 16</strong>
                    </p>
                  </div>
                  <p className="top-space">AFGJI/13/5/ADM</p>
                  <p className="text-center"><b>CIRCULAR: ADVISORY TO PARENTS</b></p>
                  <p><b>Dear Parent,</b></p>
                  <p>1. School will be reopening after summer vacations wef 04 Jul 16, all the buses will play on its scheduled routes and timings.</p>
                  <p>2. You are requested to ensure that your ward boards the bus on time and please ensure that primary wing/Special wing children are accompanied by parents /guardians while boarding/de-boarding the bus.</p>
                  <p>3. No requests for leaving the children at any place other than authorized stops will be accepted by School transport crew travelling in the bus.</p>
                  <p>4. Please help us in taking care of safety & security of our students.</p>
                  <p>5. School does not promote any private cab/Taxi/RTV or any form of private pick up drop service. The onus and responsibility of hiring a private service solely lies with parents.</p>
                  <p>6. School buses are safer mode of transportation; hence, it is advised to use school buses as far as possible.</p>
                  <p>7. School is not to be held responsible in case of any incident /accident occurring due to usage of any transport other than School buses.</p>
                  <p><b>(Poonam S Rampal)<br /> Principal</b></p>
                  <div className="height-40"></div>
                </div>
              </div>
            </div>
          
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Transport;
