
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
const RulesRegulation = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Rules & Regulation" breadcrumbName="Rules & Regulation" />

      <div className="container body_height">
        <div className="row">
          <div className="col-md-12">
            <ul className="checkList">
              <li>Pupils are strictly forbidden from leaving the school premises during the regular school hours. Permission to do so will be granted only by the Principal/Vice Principal/Headmistress/ Administrative Officer.</li>
              <li>Pupils on their way to and from the school are expected to conduct themselves in a responsible manner.</li>
              <li>Pupils who expect to return home late are responsible for informing their parents in advance.</li>
              <li>Care should be taken to keep the school building and surroundings clean. Ink should not be sprinkled on the walls and floor. Waste paper and other litter should be put in the dustbin. Pupils must not write on walls, furniture. Defacing bathroom walls is prohibited.</li>
              <li>School property willfully/negligently damaged or destroyed will have to be made good by the parents of concerned students. A heavy fine including collective fine may also be imposed by the Principal.</li>
              <li>Pupils are strictly forbidden from introducing or circulating any objectionable literature in the school.</li>
              <li>Parents are requested to co-operate in the work of the school by enforcing regularity and discipline by evincing interest in their children’s progress and by extending the fullest encouragement to their wards to participate in co-curricular and extracurricular activities. Parents should check the school diary regularly. They are requested to go through the remarks, if any, from the class teacher, the subject teacher or the House Master and follow instructions accordingly. When such remarks are made in the diary the Parent's/ Guardian's signature is essential. Failure to comply with this rule may lead to disciplinary action.</li>
              <li>Running and shouting and unnecessary movement in corridors in the school area are not allowed.
                When using staircase and corridors pupils must keep to the left.<br />
                Chewing bubble gum and spitting on the floor, writing and marking on the walls is strictly prohibited.<br />
              </li>
              <li>During the period of work experience/PT/Library etc. all students to go to the respective departments and not loiter around in corridors. Strict action will be taken against the defaulters. </li>
              <li>The school is not responsible for goods or money lost. Money should neither be lent/borrowed nor articles be exchanged within the school. </li>
              <li>Student are not allowed to bring cell phones/ IPods to school.</li>
              <li>Cell phones/ IPods will be confiscated.</li>
              <p><b>First offence - </b>Fine Rs. 500 & release of phone after 3 months.</p>
              <p><b>Repeat offence -</b> Fine Rs.1000 & release of phone after 1 year.</p>
            </ul>
            <h5><b>In Class</b></h5>
            <ul className="checkList">
              <li>No pupil will go to sick-bay during working hours without permission.</li>
              <li>Breakages of furniture, fitting etc. must immediately be reported to the Class Teacher.

                <p>(a) The cost of repairs due to damage to the school property will be charged to the pupil concerned together with a fine.</p>
                <p>(b) A token fine of Rs. 100/ actual cost of the damaged article will be charged per student for breakages/tampering.</p>
                <p>(c) Any damage to the Smart Board and its components will be recovered by from the class on the actual cost.</p>
              </li>
              <li>Students should not bring valuables and costly items like transistors, calculators,
                watches, jewellery, cell phones, iPods etc. to the school meant purely for entertainment.</li>
              <li>The school is not responsible for the loss of any such personal belongings.</li>
            </ul>
            <br />
            <h4><b>DISCIPLINE MEASURES</b></h4>
            <p>1. Irregular attendance, unauthorised absence, habitual late coming, disobedience or objectionable behaviour of any kind may result in expulsion of a student from the school.</p>
            <p>2. If a student uses unfair means, misbehaviour in the examination, she/he is liable to be punished severely.</p>
            <p>3. Three warnings will be given before any action is taken by the discipline committee.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default RulesRegulation