import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { getNews } from "../Service/Api";
const News = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNews();
      setData(datas);
    };
    getData();
  }, []);
  const placeholderEntries = [
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" },
    { title: "News Title", description: "Stay Tuned For More Updates" }
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };



  return (
    <>
      <div className="eventbg">
        <Slider {...settings}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <div key={index}>
            <div className="newbox">
            <h2>{item.title}</h2>
            <h3><Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank"> <i className="bi bi-paperclip"></i></Link>{item.description}</h3>
          </div><hr/>
          </div>
            ))
          ) : (
            placeholderEntries.map((item, index) => (
              <div className="newbox">
              <h2>News Title</h2>
              <h3><Link to="/" target="_blank"> <i className="bi bi-paperclip"></i></Link>Stay Tuned For More Updates</h3>
            </div> 
            ))
          )}
        </Slider>
       
      </div>
    </>
  );
};

export default News;
