import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { getTopper } from '../Service/Api';

const TopperXII = () => {
    const [classXIIData, setClassXIIData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const topperData = await getTopper();
            const currentYear = new Date().getFullYear();
            const yearString = `${currentYear - 1}-${currentYear}`;

            const classXII = topperData.filter((item) => item.class === 'XII' && item.sessionYear === yearString); // Adjust the filtering condition as needed
            setClassXIIData(classXII);

            console.log("Filtered class X data for year", yearString, classXII);
        };
        fetchData();
    }, []);


    const emptyArray = [
        { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
    ];

    const options = {
        margin: 30,
        dots: false,
        nav: false,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        },
    };
    return (
        <>
            <div className="row">
                {classXIIData.length > 0 && (
                    <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
                        {classXIIData.map((item1, index) => (

                            <div className="item" key={index}>
                                <div className="toppersec">
                                    <div className="col-xs-4 col-sm-4 col-md-5">
                                        <div className="row"><img src={`https://webapi.entab.info/api/image?url=${item1.attachments}`} className="img-responsive" alt="AFGJI" /> </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-7">
                                        <div className="row">
                                            <h4>Class 12 Toppers</h4>
                                            <h5>{item1?.name}</h5>
                                            <h5>{item1?.stream}</h5>
                                            <h6> {item1?.division}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                )}
                {classXIIData.length === 0 && (
                    <OwlCarousel className="owl-theme emptyFeild" loop margin={10} nav {...options}>
                        {emptyArray.map((data, index) => (
                            <div className="item" key={index}>
                                <div className="toppersec">
                                    <div className="col-xs-4 col-sm-4 col-md-5">
                                        <div className="row"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/topper.jpg" className="img-responsive" alt="AFGJI" /> </div>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-7">
                                        <div className="row">
                                            <h4>Class 12 Toppers</h4>
                                            <h5>Student Name</h5>
                                            <h5>Subject</h5>
                                            <h6>Percentage</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                )}
            </div>
        </>
    );
};

export default TopperXII;
