import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from '../Component/Footer'
import Breadcrumb from "../Component/Breadcrumb";
const ContactUs = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Contact Us" breadcrumbName="Contact Us" />

      <div className="container body_height">
        <div className="row">
          <div className="col-md-5 link_color">
            <p>We are located in Capital of India, New Delhi.<br /><br />
              <strong>Our Base:</strong> <br />Air Force Golden Jubilee Institute<br />
              Subroto Park, New Delhi<br /><br />
              <strong>Tel. No. :</strong> 011-25692819<br />
              <strong>Email :</strong> <Link to="mailto:afgjisp@gmail.com">afgjisp@gmail.com</Link><br />
              <strong>Website :</strong> <Link to="http://www.afgji.in/" target="_blank">www.afgji.in</Link></p>
          </div>
          <div className="col-md-7">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.5962166344034!2d77.14635801516289!3d28.58188538243781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b3b69de44b%3A0x95acaea9e5c00f56!2sAir+Force+Golden+Jubilee+Institute!5e0!3m2!1sen!2sin!4v1499926235314" width="100%" height="300" frameborder="0" style={{border:'0'}} allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ContactUs
