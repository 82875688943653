import React from 'react'
import { Link } from 'react-router-dom'

import Breadcrumb from "../Component/Breadcrumb"; 
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
const Pphelp = () => {
  return (
    <>
     <Header />
            <Breadcrumb pageTitle="Portal (Help?)" breadcrumbName="Portal (Help?)" />
 
 
<div className="container body_height">
  <div className="row">
    <div className="col-md-12">
        <div>
        <h3 className="sub_head">CampusCare®  Application Guidelines Instructions</h3>
        <h3 className="sub-head-1">How to LOG IN Parent Portal?</h3>
        <p className="details"> 1. Open Google Chrome <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/chrome.png" alt="AFGJI"/> / Mozilla Firefox <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/mzilla.png" alt="AFGJI"/> or Internet Explorer <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/internet.png" alt="AFGJI"/> </p>
        <p className="details">2. Write the school URL <a href="http://www.afgjicampuscare.in/" target="_blank">"<b>afgjicampuscare.in</b>" </a> in address bar. (you will be routed to the Log In credential page)</p>
        <p className="details">3. After clicking on PARENTS menu, enter the USER ID, PASSWORD and CAPTCHA CODE. Then click SIGN IN button.</p>
        <br/>
        <p className="details"> Once logged in, you are welcomed with various information relating to assignments, news, circulars, date sheet, fee details etc. that are self explanatory.  Wish you to go through every links that are provided and explore more.</p>
      </div>
      <div className="clr10"></div>

      <div>
        <h3 className="sub-head-1">How to RESET PASSWORD in Parent Portal?</h3>
        <p className="details"> 1. Open Google Chrome <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/chrome.png" alt="AFGJI"/> / Mozilla Firefox <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/mzilla.png" alt="AFGJI"/> or Internet Explorer <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/internet.png" alt="AFGJI"/> </p>
        <p className="details">2. Write the school URL <a href="http://www.afgjicampuscare.in/" target="_blank">"<b>afgjicampuscare.in</b>" </a> in address bar. (you will be routed to the Log In credential page)</p>
        <p className="details"> 3. After click on PARENTS menu, click on “Forgot your password?” text given below the Sign In button.</p>
        <p className="details"> 4. You will get a reset password window: Enter the details asked for. Then click on “Reset” Button.</p>
        <p className="details"> 5. You will get an SMS on your registered mobile no. along with login credentials. </p>
      </div>
 
      <div className="clr10"></div>

      <div className="assistance">
        <h3 className="sub-head-1">Please call or write for assistance</h3>
        <p className="details">PARENT HELP DESK (8:00 AM to 8:00 PM) </p>

        <p className="details"> <strong>E-Mail : </strong> <a href="mailto:parentdesk@entab.in"> <b>parentdesk@entab.in</b></a></p>
      </div>
    </div>
  </div>
</div>
<Footer />
    </>
  )
}

export default Pphelp
