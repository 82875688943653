
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
const Virtualtour = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Virtual Tour" breadcrumbName="Virtual Tour" />
            
            <div className="container body_height">
                <div className="row"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/cm.png" className="img-responsive center-block" alt="AFGJI" /> </div>
            </div>
            <Footer />
        </>
    )
}

export default Virtualtour