import React, { useState, useEffect } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom';

const Syllabus = () => {
  const [activeTab, setActiveTab] = useState('Syllabus_2025_1');
  const [selectedYear, setSelectedYear] = useState('2024-25');

  useEffect(() => {
    // Reset the active tab to the default tab for the new year
    if (selectedYear === '2024-25') {
      setActiveTab('Syllabus_2025_1');
    } else if (selectedYear === '2023-24') {
      setActiveTab('Syllabus_2024_1');
    } else if (selectedYear === '2022-23') {
      setActiveTab('Syllabus_2023_1');
    } else if (selectedYear === '2021-22') {
      setActiveTab('Syllabus_2022_1');
    } else if (selectedYear === '2020-21') {
      setActiveTab('Syllabus_2020_1');
    
    }  
  }, [selectedYear]);
  return (
    <>
      <Header />
      <div className="container-fluid innerbg">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6">
              <h2 className="innerhead">Syllabus</h2>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6">
              <select
                id="ddlYear"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="2024-25">2024-25</option>
                <option value="2023-24">2023-24</option>
                <option value="2022-23">2022-23</option>
                <option value="2021-22">2021-22</option>
                <option value="2020-21">2020-21</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="container body_height">
        {/* Only showing the content of the selected year */}
        {selectedYear === '2024-25' && (
          <div id="showSix" className="myDiv selected_year">
            <div className="col-md-3 leftmenu">
              <div className="leftmenubg">
                <p className="menuhead">Important Links</p>
              </div>
              <div id="events">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className={activeTab === 'Syllabus_2025_1' ? 'active' : ''}>
                    <Link to="#Syllabus_2025_1" onClick={() => setActiveTab('Syllabus_2025_1')} aria-controls="Syllabus_2025_1" role="tab">Primary Wing</Link>
                  </li>
                  <li role="presentation" className={activeTab === 'Syllabus_2025_2' ? 'active' : ''}>
                    <Link to="#Syllabus_2025_2" onClick={() => setActiveTab('Syllabus_2025_2')} aria-controls="Syllabus_2025_2" role="tab">Senior Wing</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="tab-content">
                {activeTab === 'Syllabus_2025_1' && (
                  <div role="tabpanel" className="tab-pane active" id="Syllabus_2025_1">
                    <h3 className="sub_head">Primary Syllabus</h3>

                    <div className="row">
                    <h4>SYLLABUS FOR PERIODIC TEST -II (2024-25)</h4>


                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/Class-I-PT-2.pdf" target="_blank">Class 1</Link> </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/Class-II-PT-2.pdf" target="_blank">Class 2</Link> </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/Class-III-PT-2.pdf" target="_blank">Class 3</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/Class-IV-PT-2.pdf" target="_blank">Class 4</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/Class-V-PT-2.pdf" target="_blank">Class 5</Link> </div>
                      </div>


                      <h4>SYLLABUS FOR PERIODIC TEST -I (2024-25)</h4>
                      
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class1.pdf" target="_blank">Class 1</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class2.pdf" target="_blank">Class 2</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class3.pdf" target="_blank">Class 3</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class4.pdf" target="_blank">Class 4</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class5.pdf" target="_blank">Class 5</Link> </div>
                      </div>

                      <div className="clr25"></div>
                      <h4>TERM WISE SYLLABUS 2024-25</h4>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/termwise-class1.pdf" target="_blank">Class 1</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/termwise-class2.pdf" target="_blank">Class 2</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/termwise-class3.pdf" target="_blank">Class 3</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/termwise-class4.pdf" target="_blank">Class 4</Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/termwise-class5.pdf" target="_blank">Class 5</Link> </div>
                      </div>

                      <div class="clr25"></div>
                      <h4>MID TERM SYLLABUS FOR CLASSES I to V</h4>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/syllabus-mid-term-class-1.pdf" target="_blank"> Class 1 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/syllabus-mid-term-class-2.pdf" target="_blank"> Class 2 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/syllabus-mid-term-class-3.pdf" target="_blank"> Class 3 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/syllabus-mid-term-class-4.pdf" target="_blank"> Class 4 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2024-25/syllabus-mid-term-class-5.pdf" target="_blank"> Class 5 </Link> </div>
                      </div>

                    </div>
                  </div>
                )}
                {activeTab === 'Syllabus_2025_2' && (
                  <div role="tabpanel" className="tab-pane active" id="Syllabus_2025_2">
                    <h3 class="sub_head">Senior Syllabus</h3>
                    <div class="row">
                      <h4> ANNUAL SYLLABUS 2024-25</h4>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-6.pdf" target="_blank"> Class 6 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-7.pdf" target="_blank"> Class 7 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-8.pdf" target="_blank"> Class 8 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-9.pdf" target="_blank"> Class 9 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-10.pdf" target="_blank"> Class 10 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-11-commerce.pdf" target="_blank"> Class 11 Commerce  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-11-science.pdf" target="_blank"> Class 11 Science </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-11-humanities.pdf" target="_blank"> Class 11 Humanities  </Link> </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-12-commerce.pdf" target="_blank"> Class 12 Commerce  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-12-humanities.pdf" target="_blank"> Class 12 Humanities  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/annual-class-12-science.pdf" target="_blank"> Class 12 Science </Link> </div>
                      </div>
                    </div>
                    <div class="row">
                      <h4> MID TERM SYLLABUS 2024-25</h4>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-6.pdf" target="_blank"> Class 6 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-7.pdf" target="_blank"> Class 7 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-8.pdf" target="_blank"> Class 8 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-9.pdf" target="_blank"> Class 9 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-10.pdf" target="_blank"> Class 10 </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-11-commerce.pdf" target="_blank"> Class 11 Commerce  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-11-science.pdf" target="_blank"> Class 11 Science </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-11-humanities.pdf" target="_blank"> Class 11 Humanities  </Link> </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-12-commerce.pdf" target="_blank"> Class 12 Commerce  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-12-humanities.pdf" target="_blank"> Class 12 Humanities  </Link> </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2024-25/class-12-science.pdf" target="_blank"> Class 12 Science </Link> </div>
                      </div>
                    </div>
                  </div>


                )}
              </div>
            </div >
          </div >
        )}
       
        {
          selectedYear === '2023-24' && (
            <div id="showFive" className="myDiv selected_year">
               <div className="col-md-3 leftmenu">
        <div className="leftmenubg">
          <p className="menuhead">Important Links</p>
        </div>
        <div id="events">
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className={activeTab === 'Syllabus_2024_1' ? 'active' : ''}>
              <Link to="#Syllabus_2024_1" onClick={() => setActiveTab('Syllabus_2024_1')} aria-controls="Syllabus_2024_1" role="tab">
                Primary Wing
              </Link>
            </li>
            <li role="presentation" className={activeTab === 'Syllabus_2024_2' ? 'active' : ''}>
              <Link to="#Syllabus_2024_2" onClick={() => setActiveTab('Syllabus_2024_2')} aria-controls="Syllabus_2024_2" role="tab">
                Senior Wing
              </Link>
            </li>
          </ul>
        </div>
      </div>
              <div className="col-md-9">
                <div className="tab-content">
                {activeTab === 'Syllabus_2024_1' && (
                      <div role="tabpanel" className="tab-pane active" id="Syllabus_2024_1">
                    <h3 class="sub_head">Primary Syllabus</h3>
         <div class="clr25"></div>
    <div class="row">
    <h4>Final Term Syllabus 2023-24</h4>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-1-Syllabus-Final-Term.pdf" target="_blank">Class 1st</Link> </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-2-Syllabus-Final-Term.pdf" target="_blank">Class 2nd </Link> </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-3-Syllabus-Final-Term.pdf" target="_blank">Class 3rd </Link> </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-4-Syllabus-Final-Term.pdf" target="_blank">Class 4th </Link> </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
        <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-5-Syllabus-Final-Term.pdf" target="_blank">Class 5th </Link> </div>
    </div>
   <div class="clr25"></div>
   <h4>PERIODIC TEST-2 2023-24</h4>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Periodic-class-1.pdf" target="_blank"> Class 1 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Periodic-class-2.pdf" target="_blank"> Class 2 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Periodic-class-3.pdf" target="_blank"> Class 3 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Periodic-class-4.pdf" target="_blank"> Class 4 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Periodic-class-5.pdf" target="_blank"> Class 5 </Link> </div>
   </div>
   <div class="clr25"></div>



   <h4>MID TERM SYLLABUS 2023-24</h4>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/syllabus-mid-term-class-1.pdf" target="_blank"> Class 1 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/syllabus-mid-term-class-2.pdf" target="_blank"> Class 2 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/syllabus-mid-term-class-3.pdf" target="_blank"> Class 3 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/syllabus-mid-term-class-4.pdf" target="_blank"> Class 4 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/syllabus-mid-term-class-5.pdf" target="_blank"> Class 5 </Link> </div>
   </div>
   <div class="clr25"></div>
   <h4>TERM WISE SYLLABUS 2023-24</h4>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-ukg.pdf" target="_blank"> Class UKG </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-1.pdf" target="_blank"> Class 1 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-2.pdf" target="_blank"> Class 2  </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-3.pdf" target="_blank"> Class 3 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-4.pdf" target="_blank"> Class 4 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/class-5.pdf" target="_blank"> Class 5 </Link> </div>
   </div>
   <div class="clr25"></div>
   <h4>SYLLABUS FOR PERIODIC TEST-1 (2023-24)</h4>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-1-PT-1.pdf" target="_blank"> Class 1 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-2-PT-1.pdf" target="_blank"> Class 2 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-3-PT-1.pdf" target="_blank"> Class 3 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-4-PT-1.pdf" target="_blank"> Class 4 </Link> </div>
   </div>
   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2023-24/Class-5-PT-1.pdf" target="_blank"> Class 5 </Link> </div>
   </div>
</div>

          <div class="clr25"></div>
                    </div>
                  )}

{activeTab === 'Syllabus_2024_2' && (
                 <div role="tabpanel" className="tab-pane active" id="Syllabus_2024_2">
                      <h3 class="sub_head">Senior Syllabus</h3>
            <div class="row">
            
             <h4>SYLLABUS FOR PERIODIC TEST-2 </h4>		
             <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/Class-10-PT-2.pdf" target="_blank"> PT 2 SYLLABUS -CLASS 10</Link> </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/Class-12-PT-2.pdf" target="_blank"> PT SYLLABUS class 12 </Link> </div>
            </div>

            
            </div>


              <div class="row">

			  <h4>ANNUAL SYLLABUS 2023-24</h4>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-6.pdf" target="_blank"> Class 6 </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-7.pdf" target="_blank"> Class 7 </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-8.pdf" target="_blank"> Class 8 </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-9.pdf" target="_blank"> Class 9 </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-10.pdf" target="_blank"> Class 10 </Link> </div>
            </div>



             <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-11-commerce.pdf" target="_blank"> Class 11 Commerce  </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-11-humanities.pdf" target="_blank"> Class 11 Humanities  </Link> </div>
            </div>
			 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-11-science.pdf" target="_blank"> Class 11 Science </Link> </div>
            </div>



			 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-12-commerce.pdf" target="_blank"> Class 12 Commerce  </Link> </div>
            </div>
			<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-12-humanities.pdf" target="_blank"> Class 12 Humanities  </Link> </div>
            </div>
			 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/2023-24/class-12-science.pdf" target="_blank"> Class 12 Science </Link> </div>
            </div>



                </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }
        {
          selectedYear === '2022-23' && (
            <div id="showFour" className="myDiv selected_year">
              <div className="col-md-3 leftmenu">
                <div className="leftmenubg">
                  <p className="menuhead">Important Links</p>
                </div>
                <div id="events">
                  <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className={activeTab === 'Syllabus_2023_1' ? 'active' : ''}>
                      <Link to="#Syllabus_2023_1" onClick={() => setActiveTab('Syllabus_2023_1')} aria-controls="Syllabus_2023_1" role="tab">
                        Primary Wing
                      </Link>
                    </li>
                    <li role="presentation" className={activeTab === 'Syllabus_2023_2' ? 'active' : ''}>
                      <Link to="#Syllabus_2023_2" onClick={() => setActiveTab('Syllabus_2023_2')} aria-controls="Syllabus_2023_2" role="tab">
                        Senior Wing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-9">
                <div className="tab-content">
                  {activeTab === 'Syllabus_2023_1' && (
                    <div role="tabpanel" className="tab-pane active" id="Syllabus_2023_1">
                   <h3 class="sub_head">Primary Syllabus</h3>
                <div class="clr25"></div>
          <div class="row">
          		 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/fortnightly-syllabus-15-dec-22.pdf" target="_blank">FORTNIGHTLY SYLLABUS 29 Nov 22 TO 15 Dec 22 </Link> </div>
				</div>
          		 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/fortnightly-syllabus-15-Nov-22.pdf" target="_blank">FORTNIGHTLY SYLLABUS 01 Nov 22 TO 15 Nov 22 </Link> </div>
				</div>		  
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/PW-fortnightly-syllabus-31aug.pdf" target="_blank">FORTNIGHTLY SYLLABUS 16 Aug 22 To 31 Aug 22 </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-I-Syllabus-2022.pdf" target="_blank">MID TERM 2022- Class I </Link> </div>
				</div>			  
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-II-Syllabus-2022.pdf" target="_blank">MID TERM 2022- Class II </Link> </div>
				</div>			  
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-III-Syllabus-2022.pdf" target="_blank">MID TERM 2022- Class III </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-IV-Syllabus-2022.pdf" target="_blank">MID TERM 2022- Class IV </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-V-Syllabus-2022.pdf" target="_blank">MID TERM 2022- Class V </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/FORTNIGHTLY-SYLLABUS.pdf" target="_blank">FORTNIGHTLY SYLLABUS </Link> </div>
				</div>
                <div class="clr25"></div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-UKG.pdf" target="_blank">Class UKG </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-1.pdf" target="_blank">Class 1 </Link> </div>
				</div>
                 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-2-english.pdf" target="_blank">Class 2 English </Link> </div>
				</div>                
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-2-evs.pdf" target="_blank">Class 2 EVS </Link> </div>
				</div>
                 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-2-gk.pdf" target="_blank">Class 2 GK </Link> </div>
				</div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-2-hindi.pdf" target="_blank">Class 2 Hindi </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-2-maths.pdf" target="_blank">Class 2 Maths </Link> </div>
				</div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-3-evs.pdf" target="_blank">Class 3 EVS </Link> </div>
				</div>
                   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-3-hindi.pdf" target="_blank">Class 3 Hindi </Link> </div>
				</div>
                 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-3-english.pdf" target="_blank">Class 2 English </Link> </div>
				</div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-3-maths.pdf" target="_blank">Class 3 Maths </Link> </div>
				</div>
                   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-4.pdf" target="_blank">Class 4  </Link> </div>
				</div>
                 <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2022-23/Class-5.pdf" target="_blank">Class 5 </Link> </div>
				</div>  
            </div>
          <div class="clr25"></div>
                    </div>
                  )}

                  {activeTab === 'Syllabus_2023_2' && (
                    <div role="tabpanel" className="tab-pane active" id="Syllabus_2023_2">
                      <h3 class="sub_head">Senior Syllabus</h3>
              <div class="row">
              	 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-6-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 6 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-7-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 7 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-8-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 8 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-9-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 9 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-10-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 10 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-11-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 11 Annual Syllabus </Link> </div>
            </div>
			 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div class="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/datesheet/Secondary/class-12-All-Subjects-Syllabus-22-23.pdf" target="_blank"> Class 12 Annual Syllabus </Link> </div>
            </div>
                </div>
                    </div>
                  )}
                </div>
              </div>
            </div>)
        }





        {
          selectedYear === '2021-22' && (
            <div id="showThree" className="myDiv selected_year">
              <div className="col-md-3 leftmenu">
                <div className="leftmenubg">
                  <p className="menuhead">Important Links</p>
                </div>
                <div id="events">
                  <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className={activeTab === 'Syllabus_2022_1' ? 'active' : ''}>
                      <Link to="#Syllabus_2023_1" onClick={() => setActiveTab('Syllabus_2022_1')} aria-controls="Syllabus_2022_1" role="tab">
                        Primary Wing
                      </Link>
                    </li>
                    <li role="presentation" className={activeTab === 'Syllabus_2022_2' ? 'active' : ''}>
                      <Link to="#Syllabus_2022_2" onClick={() => setActiveTab('Syllabus_2022_2')} aria-controls="Syllabus_2022_2" role="tab">
                        Senior Wing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-9">
                <div className="tab-content"> 
                  {activeTab === 'Syllabus_2022_1' && (
                    <div role="tabpanel" className="tab-pane active" id="Syllabus_2022_1">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p class="text-left"><strong>TERM 2 (UT4)</strong> </p>
                        </div>
                        <div class="clr25"></div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/UT4-class-1.pdf" target="_blank">Class 1</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/UT4-class-2.pdf" target="_blank">Class 2</Link> </div>
                        </div>


                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/UT4-class-3.pdf" target="_blank">Class 3</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/UT4-class-4.pdf" target="_blank">Class 4</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/UT4-class-5.pdf" target="_blank">Class 5</Link> </div>
                        </div>

                      </div>








                      <div className="clr25"></div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p className="text-left"><strong>Term-1 (UT 2)  Revised Syllabus</strong> </p>
                        </div>
                        <div className="clr25"></div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/Class1-UT-2.pdf" target="_blank">Class 1</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/Class2-UT-2.pdf" target="_blank">Class 2</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/Class3-UT-2.pdf" target="_blank">Class 3</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/Class4-UT-2.pdf" target="_blank">Class 4</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term2/Class5-UT-2.pdf" target="_blank">Class 5</Link> </div>
                        </div>

                      </div>

                      <div className="clr25"></div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p className="text-left"><strong>Unit Test 1 </strong> </p>
                        </div>
                        <div className="cl25"></div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class1-UT 1.pdf" target="_blank">Class 1</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class2-UT 1.pdf" target="_blank">Class 2</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class3-UT 1.pdf" target="_blank">Class 3</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class4-UT 1.pdf" target="_blank">Class 4</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class5-UT 1.pdf" target="_blank">Class 5</Link> </div>
                        </div>

                      </div>
                      <div className="clr25"></div>

                      <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p className="text-left"><strong>Term 1 </strong> </p>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_UKG.pdf" target="_blank">Class UKG</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/CLASS_1.pdf" target="_blank">Class 1 Term Wise Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/CLASS_2_maths.pdf" target="_blank">Class 2  Maths Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_2_English.pdf" target="_blank">Class 2 English Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_2_Hindi.pdf" target="_blank">Class 2 Hindi Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_2_EVS.pdf" target="_blank">Class 2 EVS Syllabus </Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_3_maths.pdf" target="_blank">Class 3 Maths Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_3_English.pdf" target="_blank">Class 3 English Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_3_HINDI.pdf" target="_blank">Class 3 Hindi Syllabus </Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_3_EVS.pdf" target="_blank">Class 3 EVS Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_3_GK.pdf" target="_blank">Class 3 GK Syllabus</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_4.pdf" target="_blank">Class 4</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Class_5.pdf" target="_blank">Class 5</Link> </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/Term1/Computer_syllabus.pdf" target="_blank">Computer Syllabus </Link> </div>
                        </div>
                      </div>

                    </div>
                  )}
 
                  {activeTab === 'Syllabus_2022_2' && (
                    <div role="tabpanel" className="tab-pane active" id="Syllabus_2022_2">
                      <h3 className="sub_head">Primary Syllabus</h3>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <p className="text-left"><strong>MID TERM EXAMS CLass 9 TO 12 classes</strong> </p>
                        </div>
                        <div className="cl25"></div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/mid-term/MID-TERM-SYLLABUS-IX.pdf" target="_blank">Class 9</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/mid-term/MID-TERM-SYLLABUS-X.pdf" target="_blank">Class 10</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/mid-term/MID-TERM-SYLLABUS-XI.pdf" target="_blank">Class 11</Link> </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/2021-22/mid-term/MID-TERM-SYLLABUS-XII.pdf" target="_blank">Class 12</Link> </div>
                        </div>


                      </div>
                      <div className="cl25"></div>
                      <h3 className="sub_head">Secondary Syllabus</h3>
                      <p className="text-left"><strong>Annual Syllabus 2021-2022</strong> </p>
                      <div className="cl25"></div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-VI.pdf" target="_blank">All Subjects Syllabus Class-VI </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-VII.pdf" target="_blank">All Subjects Syllabus Class-VII </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-VIII.pdf" target="_blank">All Subjects Syllabus Class-VIII </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-IX.pdf" target="_blank">All Subjects Syllabus Class-IX </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-X.pdf" target="_blank">All Subjects Syllabus Class-X </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-XI.pdf" target="_blank">All Subjects Syllabus Class-XI </Link> </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/All-Subjects-Syllabus-Class-XII.pdf" target="_blank">All Subjects Syllabus Class-XII </Link> </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        } 
         {
          selectedYear === '2020-21' && (
            <div id="showTwo" className="myDiv selected_year">
            <div className="col-md-3 leftmenu">
              <div className="leftmenubg">
                <p className="menuhead">Important Links</p>
              </div>
              <div id="events">
              <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className={activeTab === 'Syllabus_2020_1' ? 'active' : ''}>
                      <Link to="#Syllabus_2020_1" onClick={() => setActiveTab('Syllabus_2020_1')} aria-controls="Syllabus_2020_1" role="tab">
                        Primary Wing
                      </Link>
                    </li>
                    <li role="presentation" className={activeTab === 'Syllabus_2020_2' ? 'active' : ''}>
                      <Link to="#Syllabus_2020_2" onClick={() => setActiveTab('Syllabus_2020_2')} aria-controls="Syllabus_2020_2" role="tab">
                        Senior Wing
                      </Link>
                    </li>
                  </ul>
              </div>
            </div>
      
            <div className="col-md-9">
              <div className="tab-content">
                {/* Primary Syllabus */}
                {activeTab === 'Syllabus_2020_1' && (
                  <div role="tabpanel" className="tab-pane active" id="Syllabus_2020_1">
                     <h3 className="sub_head">Primary Syllabus</h3>
            <p className="text-left"><strong>Syllabus for UT 4</strong> </p>
            <div className="cl25"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-UT-4.pdf" target="_blank">Class 1st</Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-UT-4.pdf" target="_blank">Class 2nd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-UT-4.pdf" target="_blank">Class 3rd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-UT-4.pdf" target="_blank">Class 4th </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-UT-4.pdf" target="_blank">Class 5th </Link> </div>
            </div>
            <div className="clr10"></div>
              
            <p className="text-left"><strong>Syllabus for UT 3</strong> </p>
            <div className="cl25"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Date sheet-UT-3-2020-2021.pdf" target="_blank">Date sheet for UT 3 2020-2021</Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-UT-3.pdf" target="_blank">Class 1st </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-UT-3.pdf" target="_blank">Class 2nd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-UT-3.pdf" target="_blank">Class 3rd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-UT-3.pdf" target="_blank">Class 4th </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-UT-3.pdf" target="_blank">Class 5th </Link> </div>
            </div>
            <div className="clr10"></div>
            <p className="text-left"><strong>Syllabus for UT 2</strong> </p>
            <div className="cl25"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-UT-2.pdf" target="_blank">Class 1st </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-UT-2.pdf" target="_blank">Class 2nd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-UT-2.pdf" target="_blank">Class 3rd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-UT-2.pdf" target="_blank">Class 4th </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-UT-2.pdf" target="_blank">Class 5th </Link> </div>
            </div>
            <div className="clr10"></div>
            <p className="text-left"><strong>Syllabus for UT 1</strong> </p>
            <div className="cl25"></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-1-Syllabus-2020.pdf" target="_blank">Class 1st </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-2-Syllabus-2020.pdf" target="_blank">Class 2nd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-3-Syllabus-2020.pdf" target="_blank">Class 3rd </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-4-Syllabus-2020.pdf" target="_blank">Class 4th </Link> </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Primary-Syllabus/Class-5-Syllabus-2020.pdf" target="_blank">Class 5th </Link> </div>
            </div>
            <div className="cl25"></div>
                  </div>
                )}
      
                {/* Secondary Syllabus */}
                {activeTab === 'Syllabus_2020_2' && (
                  <div role="tabpanel" className="tab-pane active" id="Syllabus_2020_2">
                             <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <h3 className="sub_head">Secondary Syllabus</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="text-left"><b>Pre Board Syllabus for class 10 and 12</b></p>
              </div>
              <div className="cl25"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-10_PB-Syllabus.pdf" target="_blank">Class X </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-12_PB-Syllabus.pdf" target="_blank">Class XII </Link> </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="text-left"><b>Annual Syllabus for class 6 to 9 and 11</b></p>
              </div>
              <div className="cl25"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-6_Annual-Syllabus.pdf" target="_blank">Class VI </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-7_Annual-Syllabus.pdf" target="_blank">Class VII </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-8_Annual-Syllabus.pdf" target="_blank">Class VIII </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class9_Annual-Syllabus.pdf" target="_blank">Class IX </Link> </div>

              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-11_Annual-Syllabus.pdf" target="_blank">Class XI </Link> </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="text-left"><b>Syllabus for PT2 Classes 6-9 (2020-21)</b></p>
              </div>
              <div className="cl25"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/PT2-class-6.pdf" target="_blank">Class 6th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/PT2-class-7.pdf" target="_blank">Class 7th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/PT2-class-8.pdf" target="_blank">Class 8th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/PT2-class-9.pdf" target="_blank">Class 9th </Link> </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="text-left"><b>Syllabus for UT2 class XI (2020-21)</b></p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/UT2-class-XI.pdf" target="_blank">Class XI </Link> </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="text-left"><b>Syllabus for PT1 Class 6 to 12 (2020-21)</b></p>
              </div>
              <div className="cl25"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 6.pdf" target="_blank">Class 6th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 7.pdf" target="_blank">Class 7th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 8.pdf" target="_blank">Class 8th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 9.pdf" target="_blank">Class 9th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 10.pdf" target="_blank">Class 10th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 11.pdf" target="_blank">Class 11th </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Syllabus class 12.pdf" target="_blank">Class 12th </Link> </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="text-left"><b>Pre-Board Exam (2020-21)</b></p>
              </div>
              <div className="cl25"></div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-X-Preboard.pdf" target="_blank">Class X </Link> </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                <div className="syllabus"> <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Secandory-Syllabus/Class-XII-Preboard.pdf" target="_blank">Class XII </Link> </div>
              </div>
            </div>
                   
                  </div>
                )}
              </div>
            </div>
          </div>
            )
          } 
      </div >

      <Footer />
    </>
  );
}

export default Syllabus;
