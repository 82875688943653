
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Fee_details = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Fee Details" breadcrumbName="Fee Details" />
            
<div className="container body_height">
     <div className="row">
	<div className="col-md-12">

    
  <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/pdf/Fee_Structure.pdf" width="100%" height="700" title="Fee Structure">
  
  </iframe>
    
    
    </div>
	</div>
</div>

                <Footer />
        </>
    )
}

export default Fee_details