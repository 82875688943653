
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
const Visionmission = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Vision & Mission" breadcrumbName="Vision & Mission" />
     
            <div className="container body_height">
                <div className="row">
                    <div className="col-sm-7 col-md-8">
                        <h3 className="subhead">Vision</h3>
                        <p>The School’s Vision is to provide “Better Tomorrow” for our children by giving them better education, which will fully equip them to face challenges of life in the field of Academics, Sports, Arts and Cultural activities with vocational and professional competence.</p>
                        <h3 className="subhead">Mission</h3>
                        <p>The integrated concept serves the purpose of inculcating values of empathy, compassion, harmony, comradeship among students.</p>
                    </div>
                    <div className="col-sm-5 col-md-4"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/innerbg/vision.jpg" className="img-responsive center-block" alt="AFGJI" /> </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Visionmission