
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb"; 
const Missionnmole = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Mission N Mole" breadcrumbName="Mission N Mole" />
 
            <div className="container toppad-30">
                <div className="row">
                    <div className="col-md-12">
                        <center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Mission-NMOLE-1.jpg" alt="AFGJI" className="img-responsive" /></center>
                    </div>
                    <div className="clr10"></div>
                    <div className="col-md-12">
                        <center><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Mission-NMOLE-2.jpg" alt="AFGJI" className="img-responsive" /></center>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Missionnmole