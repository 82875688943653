
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Ourhistory = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Our History" breadcrumbName="Our History" />
             
<div className="container body_height">
  <div className="row">
    <div className="col-md-12">
      <p>Air Force Golden Jubilee Institute, situated at Subroto Park, Delhi Cantonment is one of the leading public schools run by Indian Air Force Educational and Cultural Society.</p>
      <p>Initially in the year 1985, it started as a school only for the mentally retarded children but subsequently it was felt that an integrated Institution was a better option, which would provide the mentally handicapped children, an opportunity of receiving education in the atmosphere of a normal school.</p>
      <p>This double pronged concept served the purpose of inculcating values of compassion, harmony, comradeship among the students and also created an opportunity for education to the children with special needs and successfully gave shape to the unique idea of imparting education to the intellectually deprived in an integrated school alongwith the mainstream of normal children. The school has been imparting integrated education, since the year 1986.</p>
    </div>
  </div>
</div>
                <Footer />
        </>
    )
}

export default Ourhistory