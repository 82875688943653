import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlights = () => {
  const marqueeRef = useRef(null);
  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };
  const handleMouseOut = () => {
    marqueeRef.current.start();
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);


  const emptyArray = [
    { description: "Stay tuned for latest updates" }
  ];

  return (
    <>
      <div className="highlights">
         
        <marquee onMouseOver={(event) => event.currentTarget.stop()} onMouseOut={(event) => event.currentTarget.start()} className="highlight">
          {data.length > 0 ? (data.map((item, index) => (<p key={index}> {item.description}  {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image?url=${item.attachments[0]}`} target="_blank">  <i className="bi bi-paperclip"></i></Link>)}</p>
          ))
          ) : (
           
              <Link to="/" target="_blank"> <i className="fa fa-paperclip mrqatt" aria-hidden="true"></i>&nbsp;Stay Tuned For More Updates </Link>           )}
           

        </marquee>
        
      </div>
    </>
  )
}

export default Highlights
