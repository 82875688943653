
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb"; 
const Ourschool = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="About Our School" breadcrumbName="About Our School" />
             
<div className="container body_height">
  <div className="row">
    <div className="col-sm-12 col-md-8 col-lg-8">
      <p>Air Force Golden Jubilee Institute, located at Subroto Park, New Delhi is one of the leading public schools run by Indian Air Force Educational and Cultural Society. The School was conceptualised as an institution for special children in the Air Force Golden Jubilee year, and started in the year 1985, as a school specifically for children with special needs but subsequently it was felt that an integrated Institution would be a better option, as it would provide the special children an opportunity of receiving education in the atmosphere of a normal school.</p>
      <p>This double pronged concept served the purpose of inculcating values of compassion, harmony, comradeship among the normal students and also created an opportunity for education of special children. It was also successful in giving shape to this unique idea of imparting education to the children with special needs in an integrated school along with normal children. The school has been imparting integrated education, since the year 1986.</p>
      <p>At present an integrated group of about 2179 students and 91 students with special needs, receive education with multi disciplinary approach for their all round development.</p>
      <p>The three wings, viz. Primary, Senior and Special (Similar To Special School) , are spread over about 8 acres of land in Subroto Park, New Delhi. The school is recognized by Delhi Administration and affiliated to Central Board of Secondary Education.</p>
      <p>The school functions with the Principal as the Academic &amp; Administrative Head who is assisted by the Vice Principal, Administrative Officer, Headmistress - Primary Wing and Headmaster - Special Wing.</p>
    </div>
    <div className="col-md-4 col-lg-4 hidden-sm hidden-xs"> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/innerbg/our-school.jpg" className="img-responsive center-block" alt="AFGJI" /> </div>
  </div>
</div>
                <Footer />
        </>
    )
}

export default Ourschool