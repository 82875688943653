import Footer from "../Component/Footer"
import Header from "../Component/Header"
import Breadcrumb from "../Component/Breadcrumb"; 

const Admission = () =>{
    return(
        <>
        <Header/>
      <Breadcrumb pageTitle="Admission Information" breadcrumbName="Admission Information" />

        
<div className="container toppad-30">
     <div className="row">
          <div className="col-md-12">
               <h5><b>Schedule &amp; Points Criteria for UKG (NAF) Admission 2020-21</b></h5>
              <p>As per Dte of Edn, Govt of NCt of Delhi Circular No. F.DE.15(172)PSB/2016/12671-81 dated 13 Nov 19 regarding UKG Admission for academic year 2020-21.</p>
              <div className="tablescroll">
               <table className="table table-bordered">
                   <thead>
                       <tr className="text-center">
                           <td><b>S. No.</b></td>
                           <td><b>Particulars</b></td>
                           <td><b>Time Schedule</b></td>
                       </tr>
                   </thead>
                    <tbody>
                         <tr>
                              <td>1.</td>
                              <td>Commencement of admission process and availability of forms</td>
                              <td><b>29 Nov, 2019</b> (Friday)</td>
                         </tr>
                         <tr>
                              <td>2.</td>
                              <td>Last date of submission of application forms. </td>
                              <td><b>27 Dec, 2019</b> (Friday)</td>
                         </tr>
                         <tr>
                              <td>3.</td>
                              <td>Uploading details of children who applied to the school for admission under open seats </td>
                              <td>10 Jan, 2020 (Friday)</td>
                         </tr>
                        <tr>
                            <td>4.</td>
                            <td>Uploading marks (as per point system) given to each of the children who applied for admission under open seats.</td>
                            <td><b>17 Jan, 2020</b> (Friday)</td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>Date for display the First List of selected children (including Waiting List) (along with marks allotted under point system)</td>
                            <td><b>24 Jan, 2020</b> (Friday)</td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Resolution of queries of parent, if any, regarding allotment of points to their wards in the first list </td>
                            <td>27 Jan, 2020 (Monday) <br />to<br />03 Feb, 2020 (Monday)</td>
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td>Date of displaying the Second List of children (if any) (including Waiting List) (along with marks allotted under point system)</td>
                            <td><b>12 Feb, 2020 </b>(Wednesday) </td>
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td>Resolution of queries of parent, if any, regarding allotment of points to their wards in the second list. </td>
                            <td>13 Feb, 2020 (Thursday) <br />to<br />19 Feb, 2020 (Wednesday)</td>
                        </tr>
                        <tr>
                            <td>9.</td>
                            <td>Subsequent list of admission, if any</td>
                            <td>06 Mar, 2020 (Friday) </td>
                        </tr>
                        <tr>
                            <td>10.</td>
                            <td>Closure of admission process.</td>
                            <td>16 Mar, 2020 (Monday)  </td>
                        </tr>
                    </tbody>
               </table>
                  </div>

              <p><b>Time and Venue for sale and receipt of Forms:</b><br />
                   09:00 am -12:00 pm (except Saturdays, Sundays &amp; Govt Holidays)<br /> 
                  Air Force Bal Bharati School, Lodhi Road </p>

              <h5><b>Points Criteria for Admission to UKG Class</b></h5>
              <div className="tablescroll">
               <table className="table table-bordered">
                   <thead>
                       <tr className="text-center">
                           <td style="width:10%"><b>S. No.</b></td>
                           <td style="width:30%"><b>Criteria </b></td>
                           <td style="width:40%"><b>Specification </b></td>
                           <td style="width:10%"><b>Range of points </b></td>
                       </tr>
                   </thead>
                    <tbody>
                        <tr>
                            <td rowspan="3" className="text-center" vertical-align="middle"> 1.</td>
                            <td rowspan="3"  vertical-align="middle" className="text-center">NEIGHBOURHOOD (25 POINTS) </td>
                            <td>Upto 05 km </td>
                            <td className="text-center"><b>25</b> </td>
                        </tr>
                        <tr>
                            <td>More than 05 and upto10 km</td>
                            <td className="text-center">20 </td>
                        </tr>
                        <tr>
                            <td>More than 10 and upto 15 km</td>
                            <td className="text-center">15</td>
                        </tr>
                        <tr>
                            <td className="text-center">2. </td>
                            <td className="text-center">SIBLING STUDYING IN THE SCHOOL  (20 POINTS)</td>
                            <td>If a real brother/sister of the child is presently studying in the school.
                                (Sibling points are restricted upto first two children only except second child birth is twins).<br /> 
                                If the sibling admission was after consideration single girl child, sibling point will not be given.</td>
                            <td className="text-center"  vertical-align="middle"><b>20</b></td>
                        </tr>
                         <tr>
                            <td className="text-center">3. </td>
                            <td className="text-center">ALUMNI OF SCHOOL (10 POINTS)</td>
                            <td>Either or both parents alumni of the school </td>
                            <td className="text-center"  vertical-align="middle"><b>10</b></td>
                        </tr>
                         <tr>
                            <td className="text-center">4. </td>
                            <td className="text-center">SINGLE PARENT (10 POINTS) </td>
                            <td>Child of  Widow / Widower / Unmarried / Legally Divorced <br />
                                [Not applicable, if points claimed under Sl. No. 6] </td>
                            <td className="text-center"  vertical-align="middle"><b>10</b></td>
                        </tr>
                        <tr>
                            <td className="text-center">5. </td>
                            <td className="text-center">SINGLE GIRL CHILD (10 POINTS)</td>
                            <td className="text-center">-</td>
                            <td className="text-center"  vertical-align="middle"><b>10</b></td>
                        </tr>
                        <tr>
                            <td rowspan="7"  vertical-align="middle" className="text-center">6. </td>
                            <td rowspan="7"  vertical-align="middle" className="text-center">EX SERVICEMEN OR DECEASED DEFENCE PERSONNEL OR SERVING /DECEASED IAF CIVILIAN EMPLOYEE OR SERVING IAF NPF EMPLOYEE (25 POINTS)</td>
                            <td>(a) Children of Ex air warriors, NC(E)s </td>
                            <td className="text-center"  vertical-align="middle"><b>25 </b></td>
                        </tr>
                        <tr>
                            <td>(b) Children of Deceased air warriors/NC(E)s </td>
                            <td className="text-center"  vertical-align="middle"><b>25 </b></td>
                        </tr>
                        <tr>
                            <td>(c) Children of Ex Army/Ex Navy personnel </td>
                            <td className="text-center"  vertical-align="middle"><b>15 </b></td>
                        </tr>
                        <tr>
                            <td>(d) Children of Ex Air Force civilian</td>
                            <td className="text-center"  vertical-align="middle"><b>15 </b></td>
                        </tr>
                        <tr>
                            <td>(e) Children of IAF NPF Employees</td>
                            <td className="text-center"  vertical-align="middle"><b>15 </b></td>
                        </tr>
                        <tr>
                            <td>(f) Children of Deceased Ex Army/Ex Navy Personnel </td>
                            <td className="text-center"  vertical-align="middle"><b>15 </b></td>
                        </tr>
                        <tr>
                            <td>(g) Children of  Deceased AF Civilian Employee</td>
                            <td className="text-center"  vertical-align="middle"><b>15 </b></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="text-center"><b>TOTAL POINTS </b></td>
                            <td className="text-center"><b>100</b></td>
                        </tr>
                    </tbody>
               </table>
                  </div>
              <p><b>Note: </b>The priority for admission of children in case of tie will be decided by draw of lots. </p>
              <div className="clr25"></div>
              <h5><b>Class K. G.</b></h5>
              <p>(a) Registration will take place in December-January, for the new academic year.</p>
              <p>(b) The minimum age for admission to K. G. is 4 plus, as on 31st March, of the year of admission.</p>
              <p>(c) Admission to K. G. will take place as per the Government rules and regulations formulated from time to time.
</p>
<h5><b>Classes I-X</b></h5>
              <p>(d) Registration in the first week of April depending on availability of seats in each class.</p>
              <p>(e) Admission to be carried out through entrance test and on production of Transfer Certificates and Mark Sheets from the previous school.</p>
              <h5><b>Important:</b></h5>
              <p><b>The Registration of a student does not guarantee admission.</b></p>
              <p><b>Class-XI :</b> Registration will be carried out within 3 days of the date of declaration of Class X, AISSE examination result.</p>
         <p>The intake points for admission to school are K.G. and XI. There are few seats available in the other classes.</p>
             <h5><b>WITHDRAWALS</b></h5> 

              <ul className="checkList">
  <li>7 days notice must be given in writing for a withdrawal. Fee is payable for the month in which TC is required.</li>
            <li>A Student will be issued a Transfer Certificate only when the school dues have been cleared.</li>
              <li>I-Card &amp; Library Card must be returned to the librarian before clearance.</li>
              <li>The first and last payment of school dues must be done only in cash.</li>
              <li>In case a student is leaving Delhi, the Transfer Certificate must be counter signed by The Education Officer, Zone XX, C-4 Vasant Vihar, New Delhi.</li>
             </ul>
              </div>
     </div>
</div>
<Footer/>
        </>
    )
}
export default Admission