import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom'
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { getPrincipalMessage } from '../Service/Api'
import Breadcrumb from "../Component/Breadcrumb";
const PrincipalMessage = () => {
  useEffect(() => { 
    document.title = 'Principal Message - AFGJI';
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);

      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false)
      }
    };
    fetchData();
  }, []);
  const emptyData = [
    { id: 1 }
  ];
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Message Desk" breadcrumbName="Message Desk" />
    
      <div className="container body_height">

        <div className="row">
          <div className="col-lg-12">
            <div className='title'>
              <h3><span>Principal's</span> Message</h3>
            </div>
          </div>
        </div>

        {data?.length > 0 ? data?.filter((div) => div.category === "Principal").map((item, index) => (
          <div className="clearfix" key={index}>
            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="AFGJI" />

            <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
            <h4> <div dangerouslySetInnerHTML={{ __html: item.name }} /> </h4> <p><div dangerouslySetInnerHTML={{ __html: item.category }} /> </p>


          </div>
        )) : emptyData.map((item) => (
          <div className="clearfix">
            <div className="image_blk float-md-start msgimg">
              <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/principal-big.jpg" className="img-fluid col-md-4  mb-3 " alt="AFGJI" />
              <h5>Alka Singh</h5>
              <p>Principal</p>
            </div>
            <p>Air Force Golden Jubilee Institute is managed by the IAF Educational and Cultural Society.  It is a pioneer in the field of Inclusive Education since 1986.  Its objective is to impart the highest quality of education to all its learners, in order to create an equitable, inclusive and plural society as envisaged by our Constitution.  The reforms in school education have to be aligned with the aspirations of a ‘Viksit Bharat’ and the school is committed to create a learning environment which cultivates intellect and fortifies character.  The emphasis is on inculcating 21st century skills – critical thinking, problem solving, creativity, leadership, social skills etc.  We, at AFGJI are committed to train our students to blossom into sincere and compassionate citizens, who will be conscious of the needs of the society and the environment.  They will also have the technological prowess to suits the needs of the ever evolving global scenario.</p>
          </div>
        ))}


      </div>
      <Footer />
    </>
  )
}

export default PrincipalMessage
