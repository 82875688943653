import React, { useState } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from 'react-router-dom';
import Breadcrumb from "../Component/Breadcrumb";

const Infrastructure_primary_wing = () => {
    // Set up React state for managing the active tab
    const [activeTab, setActiveTab] = useState('ipw1');

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Infrastructure Primary Wing" breadcrumbName="Infrastructure Primary Wing" />
           
            <div className="container body_height">
                <div className="row"> 
                    <div className="col-md-3 leftmenu">
                        <div className="leftmenubg">
                            <p className="menuhead">Important Links</p>
                        </div>
                        <div id="events">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className={activeTab === 'ipw1' ? 'active' : ''}>
                                    <Link 
                                        to="#ipw1" 
                                        onClick={() => setActiveTab('ipw1')} 
                                        aria-controls="ipw1" 
                                        role="tab"
                                    >
                                        Library
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'ipw2' ? 'active' : ''}>
                                    <Link 
                                        to="#ipw2" 
                                        onClick={() => setActiveTab('ipw2')} 
                                        aria-controls="ipw2" 
                                        role="tab"
                                    >
                                        Music Room
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'ipw3' ? 'active' : ''}>
                                    <Link 
                                        to="#ipw3" 
                                        onClick={() => setActiveTab('ipw3')} 
                                        aria-controls="ipw3" 
                                        role="tab"
                                    >
                                        Dance Room
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'ipw4' ? 'active' : ''}>
                                    <Link 
                                        to="#ipw4" 
                                        onClick={() => setActiveTab('ipw4')} 
                                        aria-controls="ipw4" 
                                        role="tab"
                                    >
                                        Computer Lab
                                    </Link>
                                </li>
                                <li role="presentation" className={activeTab === 'ipw5' ? 'active' : ''}>
                                    <Link 
                                        to="#ipw5" 
                                        onClick={() => setActiveTab('ipw5')} 
                                        aria-controls="ipw5" 
                                        role="tab"
                                    >
                                        Sports
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="tab-content">  
                            {activeTab === 'ipw1' && (
                                <div role="tabpanel" className="tab-pane active" id="ipw1">
                                     <h3 className="sub_head">Library</h3>
            <p>The School has well equipped air-conditioned and computerized libraries, with over
              16,000 volumes which include books, videos, DVDs, CDs, subscription to ---
              periodicals which are added to continually. The library also provides students and
              teachers with a range of reference books, encyclopedia, journals and newspapers
              and internet facilities to enrich their classroom activities and knowledge. </p>
            <div className="pad-10"></div>
            All new books are arranged on a display table in the library for a month and reviews
            put up on the Notice Board. Our specially trained Librarian help students and
            teachers in finding the particular books on particular subjects.
            <div className="pad-10"></div>
            To encourage the reading habit Teachers and Librarian helps the students.
            <p></p>
            <div className="pad-10"></div>
            <div className="row"> </div>
            <div className="height-40"></div>
                                </div>
                            )}

                            {activeTab === 'ipw2' && (
                                <div role="tabpanel" className="tab-pane active" id="ipw2">
                                    <h3 className="sub_head">Music Room</h3>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/1.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/2.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/3.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/4.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/5.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/6.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/7.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/8.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/9.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/10.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/11.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/12.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/13.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/14.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Music/15.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="height-40"></div>
                                </div>
                            )}

                            {activeTab === 'ipw3' && (
                                <div role="tabpanel" className="tab-pane active" id="ipw3">
                                     <h3 className="sub_head">Dance Room</h3>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-dance-1.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-6 col-sm-6 col-md-6 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-dance-2.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="height-40"></div>
                                </div>
                            )}

                            {activeTab === 'ipw4' && (
                                <div role="tabpanel" className="tab-pane active" id="ipw4">
                                    <h3 className="sub_head">Computer Lab</h3>
            <p>Computer learning at AFGJI starts right from UKG where the tiny tots are imparted invaluable skills and knowledge essential in the world of Information technology. This lab offers learning aided by sound and graphics. The Lab has 14 machines with all required software packages on Windows 10 operating system</p>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0001.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0002.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0003.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="pad-10"></div>
            <div className="row">
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0004.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0005.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-4 col-sm-4 col-md-4 width-100"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/Seniorwing/Computer-SeniorWings/DSC_0006.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="height-40"></div>
                                </div>
                            )}

                            {activeTab === 'ipw5' && (
                                <div role="tabpanel" className="tab-pane active" id="ipw5">
                                    <h3 className="sub_head">Sports</h3>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-3 width-100 img-space"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-sports-1.jpg" className="img-responsive center-block"  alt="AFGJI"/></div>
              <div className="col-xs-6 col-sm-6 col-md-3 width-100 img-space"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-sports-2.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
              <div className="col-xs-6 col-sm-6 col-md-3 width-100 img-space"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-sports-3.jpg" className="img-responsive center-block"  alt="AFGJI"/></div>
              <div className="col-xs-6 col-sm-6 col-md-3 width-100 img-space"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/AFGJI/public/Images/Infrastructure/primarywing/pw-sports-4.jpg" className="img-responsive center-block" alt="AFGJI"/></div>
            </div>
            <div className="height-40"></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Infrastructure_primary_wing;
